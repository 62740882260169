import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { CacheResponsesService } from '../../services/cache-responses.service';
import { UpdateStatusService } from '../../core/services/update-status.service';
import * as $ from 'jquery';
import { Update } from '../../core/models/class/update';
import { SubjectAccessRequest } from '../../core/models/class/subject-access-request';
import { DossierAttachment } from '../../core/models/interface/dossier-attachment';
import { UpdateModeTypes, UpdateTypes } from '../../core/models/types';
import { defaultAnimation, normalizedPDF } from '../../core';
import { ToastLuncherService } from '../../core/services/toast-luncher.nb.service';
import Backend from '../../core/backend';
import { DossierUpdate } from '../../core/models/class/dossier';
import { GenericHasImageUpload } from '../../core/models/class/GenericEditFeature';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'ngx-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.scss'],
  ...defaultAnimation,
})
export class HistoriqueComponent extends GenericHasImageUpload<Update | DossierUpdate> implements OnInit {
  FILE_UPLOAD_NAME = 'file_to_upload:HistoriqueComponent';
  @Input() updates: Array<Update>;
  @Input() newUpdate = new Update();
  @Input() mode: string = '';
  @Input() type: string; // second param used for forum
  @Input() attachmentEndpoint: string;
  @Input() method: string = 'PUT';
  @Input() id: number;
  @Input() updatableAttachment: SubjectAccessRequest | DossierAttachment;
  @Output() added = new EventEmitter();

  url: string;
  MODE_COMPLAINT = UpdateModeTypes.MODE_COMPLAINT;
  MODE_DOSSIER = UpdateModeTypes.MODE_DOSSIER;
  MODE_SUBJECT_ACCESS_REQUEST = UpdateModeTypes.MODE_SUBJECT_ACCESS_REQUEST;
  MODE_FORUM = UpdateModeTypes.MODE_FORUM;
  fileLabel: string;
  noteObligatoryAccepted = false;
  noteObligatoryRejected = false;
  loading = false;
  isFilePDF: boolean = true;
  isFileSizePassed: boolean = false;
  newFile = false;
  baseUrl = Backend.baseUrl;
  isUploading = false;
  progress = 0;
  UpdateTypes: string[] = Object.keys(UpdateTypes).filter((i) => i !== UpdateTypes.RECEIVED);
  cachedList = this.cacheResponses?.getCachedList();
  isUploaded = false;
  constructor(
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    protected updateStatusService: UpdateStatusService,
    protected imageCompress: NgxImageCompressService,
    protected cacheResponses: CacheResponsesService,
  ) {
    super(_toastLuncher, imageCompress);
  }

  ngOnInit() {}

  submit() {
    if (this.newUpdate.status === UpdateTypes.RECEIVED) return;

    if (this.mode === this.MODE_SUBJECT_ACCESS_REQUEST && !this.updatableAttachment.attachment) {
      this._toastLuncher.info('الرجاء اضافة وثيقة');
    }
    this.loading = true;
    this.isUploading = true;

    if (this.newUpdate.status === UpdateTypes.ACCEPTED && !this.newUpdate.note) {
      this.noteObligatoryAccepted = true;
      this.noteObligatoryRejected = false;
      this.loading = false;
    } else if (this.newUpdate.status === UpdateTypes.REJECTED && !this.newUpdate.note) {
      this.noteObligatoryRejected = true;
      this.noteObligatoryAccepted = false;
      this.loading = false;
    } else {
      this.noteObligatoryAccepted = false;
      this.noteObligatoryRejected = false;
      this.updateStatusService.updateStatus(this.mode, this.id, this.newUpdate).subscribe(
        (data: any) => {
          this.isUploading = false;
          this.loading = false;
          this.added.emit();
          this.newUpdate = new Update();
          this._toastLuncher.success('تم التعديل بنجاح');
          this.cacheResponses.addToCachedList(data.updates[0].note);
          this.router.navigate(['/pages/' + this.router.url.split('/')[2] + '/liste']);
        },
        (error) => {
          this.loading = false;
          this.isUploading = false;
          throw new Error(' حصل خطأفي العملية ' + error.message);
        },
      );
    }
  }

  openSelectFileDialog() {
    document.getElementById('input_file').click();
  }

  onImageLoad($event, compressedImage, ...args) {
    this.newUpdate.image = compressedImage;
  }

  onFileChange(fileInput) {
    this.fileLabel = fileInput.target.files[0].name;
    const reader2 = new FileReader();
    reader2.onload = (e) => {
      this.updatableAttachment.attachment = normalizedPDF(reader2.result);
      this.uploadNewFile();
    };
    if (fileInput.target.files[0].type.match('application/pdf') && fileInput.target.files[0].size < 5300000 * 2) {
      this.newFile = true;
      this.isFilePDF = true;
      this.isFileSizePassed = false;
      reader2.readAsDataURL(fileInput.target.files[0]);
    } else if (!fileInput.target.files[0].type.match('application/pdf')) {
      this.isFilePDF = false;
      this.isFileSizePassed = false;
      throw new Error('حصل خطأفي العملية');
    } else {
      $('#helpModal').modal('toggle');
      this.isFileSizePassed = true;
      throw new Error('حصل خطأفي العملية');
    }
  }

  uploadNewFile() {
    if (this.updatableAttachment.attachment) {
      this._toastLuncher.success('جاري تحميل الملف, الرجاء الانتظار');
    }
    this.loading = true;
    if (this.method === 'PUT')
      return this.updateStatusService
        .putAttachment(this.attachmentEndpoint || this.mode, this.updatableAttachment)
        .subscribe(
          (subj) => {
            if (subj.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * subj.loaded) / subj.total);
              // this.value=this.progress
            } else if (subj.type == HttpEventType.Response) {
              if (this.updatableAttachment.attachment) {
                this._toastLuncher.success('تمت اضافة الملف بنجاح');
                this.isUploaded = true;
              } else {
                this._toastLuncher.success('تم حذف الملف');
              }
              this.updatableAttachment = subj;
              this.loading = false;
            }
          },
          (err) => {
            this.loading = false;
            throw new Error('خطأ, الرجاء اعادة تحميل الملف');
          },
        );

    return this.updateStatusService
      .postAttachment(this.attachmentEndpoint, {
        ...this.updatableAttachment,
        dossier: this.id,
      })
      .subscribe(
        (subj) => {
          this._toastLuncher.success('تمت اضافة الملف بنجاح');
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          throw new Error('خطأ, الرجاء اعادة تحميل الملف');
        },
      );
  }

  deleteAttachement() {
    this.updatableAttachment.attachment = null;
    this.uploadNewFile();
  }

  deleteImage() {
    this.newUpdate.image = null;
    this.fileName = null;
  }
}
